const ALPHA_VANTAGE_API_KEY = 'HER4K3MXXF65KTT5';

window.addEventListener('DOMContentLoaded', () => {
	const bodyEl = document.querySelector('body');

	if (bodyEl.classList.contains('wp-admin')) $ = jQuery.noConflict();

	// Cleanup PixelsmithBlocks; has modules too for some reason, need to fix
	for (const key in PixelsmithBlocks) {
		if (PixelsmithModules.hasOwnProperty(key)) delete PixelsmithBlocks[key];
	}

	let Pixelsmith = Object.assign(PixelsmithBlocks, PixelsmithModules);

	for (const key in Pixelsmith) {
		if (Pixelsmith.hasOwnProperty(key) && Pixelsmith[key].hasOwnProperty('init')) Pixelsmith[key].init();
	}

	if (window.acf) {
		window.PXCustomBlocks.forEach((b) => {
			if (PixelsmithBlocks.hasOwnProperty(b.camelCasedName)) {
				window.acf.addAction(`render_block_preview/type=${b.name}`, PixelsmithBlocks[b.camelCasedName].init);
			}
		});
	}
});
